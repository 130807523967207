import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({ providedIn: 'root' })
export class LoaderService {
    private loading: boolean = false;
    globalLoading = new BehaviorSubject(true)

    constructor() { }

    setLoading(loading: boolean) {
        this.loading = loading;
        this.globalLoading.next(this.loading)
    }

    getLoading(): boolean {
        return this.loading;
    }

    getSubject() {
        return this.globalLoading
    }
}